<template>
  <!-- Table Container Card -->
  <b-card no-body class="mb-0">
    <div class="m-0 mb-1">
      <b-row>
        <!-- Per Page -->
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>Show</label>
          <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
          <label>entries</label>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refAccountListTable"
      class="position-relative"
      :items.sync="accounts"
      responsive
      :fields="tableColumns"
      :filter.sync="searchQuery"
      :filterByFormatted="true"
      primary-key="payment.id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No accounts modified n this session"
      :sort-desc.sync="isSortDirDesc"
      :current-page.sync="currentPage"
      :per-page.sync="perPage"
      :bordered="true"
    >
      <!-- Column: Student Id -->
      <template #cell(studentId)="data">
        {{ data.item.student.myIpId }}
      </template>

      <!-- Column: Student Full Name -->
      <template #cell(student.fullName)="data">
        <b-link
          :to="{ name: 'apps-accounting-receivable-account-view', params: { id: data.item.id } }"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.item.student.fullName }}
        </b-link>
        <small class="text-muted">{{ data.item.student.myIpId }}</small>
      </template>

      <!-- Column: Cohort -->
      <template #cell(cohort)="data">
        <div class="text-nowrap" v-if="data.item.cohort">
          <b-link
            :to="{ name: 'apps-accounting-receivable-cohort-view', params: { id: data.item.cohort.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.cohort.name }}
          </b-link>
          <small class="text-muted">{{ title(data.item.cohort.status) }}</small>
        </div>
        <div class="text-nowrap" v-else>Unassigned</div>
      </template>

      <!-- Column: Attributes - Program -->
      <template #cell(profile.attributes.fundingSource)="data">
        <b-badge v-if="data.item.profile" :variant="`light-info`" class="text-nowrap text-capitalize">
          {{ title(data.item.profile.attributes.fundingSource) }}
        </b-badge>
        <div class="text-nowrap" v-else>Unassigned</div>
      </template>

      <!-- Column: balance -->
      <template #cell(payment.amount)="data">
        <span class="text-nowrap">{{ money(data.item.payment.amount) }}</span>
      </template>

      <!-- Column: Cohort -->
      <template #cell(payment.date)="data">
        {{ moment(data.item.payment.date).format('L') }}
      </template>

      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge :variant="`light-info`" class="text-capitalize">
          {{ title(data.item.payment.status) }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-center text-nowrap">
          <b-link class="text-danger" @click="markAsUnpaid(data.item)">
            <feather-icon
              :id="`un-account-row-${data.item.id}-${data.item.payment.id}-payment-icon`"
              icon="DollarSignIcon"
              class="cursor-pointer"
              size="18"
            />
            <b-tooltip
              title="Mark as Unpaid"
              class="cursor-pointer"
              :target="`un-account-row-${data.item.id}-${data.item.payment.id}-payment-icon`"
            />
          </b-link>

          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body p-0" />
            </template>

            <b-dropdown-item :to="{ name: 'apps-accounting-receivable-account-edit', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <div class="m-0">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="accounts.length"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted, onMounted, watch } from '@vue/composition-api';
import { avatarText, title } from '@core/utils/filter';
import moment from 'moment';
import router from '@/router';
import useReconcile from '@/views/apps/accounting/receivable/reconcile/useReconcile';
import { computed } from '@vue/composition-api/dist/vue-composition-api';
import { validatorEmail } from '@core/utils/validations/validators';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  data: () => {
    return {
      isAddCohortOpen: false,
      cohortAccount: undefined,
    };
  },
  methods: {
    money(v) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(v);
    },
    archiveAccount(accountId) {
      store
        .dispatch('receivableStoreModule/updateAccount', {
          id: accountId,
          accountData: {
            status: 'archived',
          },
        })
        .then((response) => {
          this.refetchData();
        });
    },
  },
  computed: {
    hasApplicableFilter() {
      return true;
    },
  },
  props: ['accounts'],
  setup(props, { emit }) {
    const {
      fetchPayments,
      markPaymentAsUnpaid,

      perPage,
      currentPage,
      totalPayments,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,

      // UI

      // Extra Filters
      statusFilter,
      statusOptions,
      fundingFilter,
      fundingSourceOptions,

      //
      dateFilter,
    } = useReconcile();

    const refAccountListTable = ref(null);

    const tableColumns = ref([
      { key: 'student.fullName', label: 'Name', stickyColumn: true, variant: 'primary', sortable: true },
      { key: 'student.email', label: 'Email', sortable: true },
      { key: 'cohort', label: 'Cohort' },
      { key: 'profile.attributes.fundingSource', label: 'Funding', sortable: true },

      { key: 'payment.date', label: 'Date', sortable: true },
      { key: 'payment.amount', label: 'Amount', sortable: true },
      { key: 'payment.description', label: 'Description', sortable: true },
      { key: 'status', sortable: true },
      { key: 'actions' },
    ]);

    const markAsUnpaid = async (account) => {
      console.log(`mark as paid`, account);

      const { id, student, payment } = account;

      console.log(id, student, payment);

      markPaymentAsUnpaid(id, payment.id || payment._id)
        .then((x) => {
          console.log(`marked as paid`, x);
          emit('completed', account);
          refetchData();
        })
        .catch((err) => {
          console.error(`failed to mark as paid`, err);
        });
    };

    const dataMeta = computed(() => {
      const localItemsCount = refAccountListTable.value ? refAccountListTable.value.localItems.length : 0;
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: props.accounts.length,
      };
    });

    return {
      // Sidebar

      fetchPayments,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAccountListTable,
      refetchData,

      // Filter
      avatarText,
      title,
      moment,

      // UI

      // Extra Filters
      statusFilter,
      statusOptions,
      fundingFilter,
      fundingSourceOptions,
      dateFilter,

      //
      markAsUnpaid,
    };
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
</style>
