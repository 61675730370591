<template>
  <b-table
    ref="refPaymentListTable"
    class="position-relative"
    :items.sync="fetchPayments"
    responsive
    :fields="tableColumns"
    :filter.sync="searchQuery"
    :filterByFormatted="true"
    primary-key="payment.id"
    :sort-by.sync="sortBy"
    show-empty
    empty-text="No matching records found"
    :sort-desc.sync="isSortDirDesc"
    :current-page.sync="currentPage"
    :per-page.sync="perPage"
    :bordered="true"
  >
    <template #cell(hotkey)="data">
      <div
        v-shortkey="['ctrl', data.index + 1]"
        @shortkey="markAsPaid(data.item)"
        style="width: 8px; padding: 0 !important; margin: 0 !important"
        class="text-nowrap"
      >
        {{ data.index + 1 }}
      </div>
    </template>

    <!-- Column: Student Id -->
    <template #cell(studentId)="data">
      {{ data.item.student.myIpId }}
    </template>

    <!-- Column: Student Full Name -->
    <template #cell(student.fullName)="data">
      <b-link
        :to="{ name: 'apps-accounting-receivable-account-view', params: { id: data.item.id } }"
        class="font-weight-bold d-block text-nowrap"
      >
        {{ data.item.student.fullName }}
      </b-link>
      <small class="text-muted">{{ data.item.student.myIpId }}</small>
    </template>

    <!-- Column: Cohort -->
    <template #cell(cohort)="data">
      <div class="text-nowrap" v-if="data.item.cohort">
        <b-link
          :to="{ name: 'apps-accounting-receivable-cohort-view', params: { id: data.item.cohort.id } }"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.item.cohort.name }}
        </b-link>
        <small class="text-muted">{{ title(data.item.cohort.status) }}</small>
      </div>
      <div class="text-nowrap" v-else-if="data.item.program && data.item.program.name">{{ data.item.program.name }}</div>
      <div class="text-nowrap" v-else>Unassigned</div>
    </template>

    <!-- Column: Attributes - Program -->
    <template #cell(profile.attributes.fundingSource)="data">
      <b-badge
        v-if="data.item.profile"
        :variant="`light-${resolveAccountProgramVariant(data.item.profile.attributes.fundingSource)}`"
        class="text-nowrap text-capitalize"
      >
        {{ title(data.item.profile.attributes.fundingSource) }}
      </b-badge>
      <div v-else class="text-nowrap">Unassigned</div>
    </template>

    <!-- Column: balance -->
    <template #cell(payment.amount)="data">
      <span class="text-nowrap">{{ money(data.item.payment.amount) }}</span>
    </template>

    <!-- Column: Cohort -->
    <template #cell(payment.date)="data">
      {{ moment(data.item.payment.date).format('L') }}
    </template>

    <!-- Column: Status -->
    <template #cell(status)="data">
      <b-badge :variant="`light-info`" class="text-capitalize">
        {{ title(data.item.payment.status) }}
      </b-badge>
    </template>

    <!-- Column: Actions -->
    <template #cell(actions)="data">
      <div class="text-center text-nowrap">
        <b-link class="text-success" @click="markAsPaid(data.item)">
          <feather-icon
            :id="`account-row-${data.item.id}-${data.item.payment.id}-payment-icon`"
            icon="DollarSignIcon"
            class="cursor-pointer"
            size="18"
          />
          <b-tooltip
            title="Mark as Paid"
            class="cursor-pointer"
            :target="`account-row-${data.item.id}-${data.item.payment.id}-payment-icon`"
          />
        </b-link>

        <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body p-0" />
          </template>

          <b-dropdown-item :to="{ name: 'apps-accounting-receivable-account-edit', params: { id: data.item.id } }">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
  </b-table>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted, onMounted, watch } from '@vue/composition-api';
import { avatarText, title } from '@core/utils/filter';
import moment from 'moment';
import router from '@/router';
import useReconcile from '@/views/apps/accounting/receivable/reconcile/useReconcile';
import { computed } from '@vue/composition-api/dist/vue-composition-api';
import { validatorEmail } from '@core/utils/validations/validators';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  data: () => {
    return {
      isAddCohortOpen: false,
      cohortAccount: undefined,
    };
  },
  methods: {
    money(v) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(v);
    },
    archiveAccount(accountId) {
      store
        .dispatch('receivableStoreModule/updateAccount', {
          id: accountId,
          accountData: {
            status: 'archived',
          },
        })
        .then((response) => {
          this.refetchData();
        });
    },
  },
  computed: {
    hasApplicableFilter() {
      return true;
    },
  },
  props: {
    querySsn: {
      type: String,
    },
    queryEmail: {
      type: String,
    },
    queryDate: {
      type: String,
    },
  },
  watch: {
    querySsn(val) {
      if (val && (val.length === 4 || val.length === 11)) {
        this.searchQuery = `ssn:${val}`;
      } else if (!val) {
        this.searchQuery = '';
      }
    },
    queryEmail(val) {
      if (val && validatorEmail(val)) {
        this.searchQuery = val;
      } else if (!val) {
        this.searchQuery = '';
      }
    },
    queryDate(val) {
      this.dateFilter = this.queryDate ? this.queryDate.split(' to ') : [];
      console.log(`querydate`, val);
    },
  },
  setup(props, { emit }) {
    const {
      fetchPayments,
      markPaymentAsPaid,

      tableColumns,
      perPage,
      currentPage,
      totalPayments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPaymentListTable,
      refetchData,

      // UI
      resolveAccountProgramVariant,

      // Extra Filters
      statusFilter,
      statusOptions,
      fundingFilter,
      fundingSourceOptions,

      //
      dateFilter,
    } = useReconcile();

    const markAsPaid = async (account) => {
      console.log(`mark as paid`, account);

      const { id, student, payment } = account;

      console.log(id, student, payment);

      markPaymentAsPaid(id, payment.id || payment._id, true)
        .then((x) => {
          console.log(`marked as paid`, x);
          emit('completed', account);
          refetchData();
        })
        .catch((err) => {
          console.error(`failed to mark as paid`, err);
        });
    };

    return {
      // Sidebar

      fetchPayments,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPaymentListTable,
      refetchData,

      // Filter
      avatarText,
      title,
      moment,

      // UI
      resolveAccountProgramVariant,

      // Extra Filters
      statusFilter,
      statusOptions,
      fundingFilter,
      fundingSourceOptions,
      dateFilter,

      //
      markAsPaid,
    };
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
</style>
