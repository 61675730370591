<template>
  <div class="container content-p">
    <component :is="'div'">
      <b-card>
        <b-col cols="12" sm="12">
          <!-- md="10" offset-md="1" lg="8" offset-lg="2" -->
          <b-row>
            <b-col cols="12" md="3" className="mb-md-0 mb-2">
              <label for="student-ssn">SSN</label>
              <cleave
                id="student-ssn"
                v-model="querySsn"
                class="form-control"
                :raw="false"
                required
                :options="inputSsnOptions"
                tabindex="1"
                autofocus
                autocomplete="off"
                @keyup.native="(ev) => keyup(ev)"
              />
            </b-col>

            <b-col cols="12" md="3" className="mb-md-0 mb-2">
              <label for="student-email">Email</label>
              <b-form-input
                id="student-email"
                v-model="queryEmail"
                type="email"
                tabindex="2"
                autocomplete="off"
                @keyup.native="(ev) => keyup(ev)"
              />
            </b-col>

            <b-col cols="12" md="3" className="mb-md-0 mb-2">
              <label for="payment-date">Scheduled For</label>
              <flat-pickr
                id="payment-date"
                v-model="queryDates"
                class="form-control"
                :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d', mode: 'range' }"
                tabindex="3"
                autocomplete="off"
              />
            </b-col>

            <b-col cols="12" md="3" className="mb-md-0 mb-2">
              <b-form-group label="Reset on Save">
                <b-form-checkbox v-model="isResetOnHotkey" class="mr-0 mt-50" name="is-reset-hotkey" switch />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-card>
      <hr />

      <b-card class="mb-0">
        <ReconcilePaymentList
          :query-date.sync="queryDates"
          :query-email.sync="queryEmail"
          :query-ssn.sync="querySsn"
          @completed="(payment) => onUpdate(payment)"
        ></ReconcilePaymentList>
      </b-card>

      <b-card v-if="markedAsPaid.length > 0" class="mt-1">
        <ReconcilePaymentSummary :accounts.sync="markedAsPaid" @completed="(payment) => onUnpaid(payment)"></ReconcilePaymentSummary>
      </b-card>
    </component>
  </div>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink, BRow, BCol, BFormInput, BFormGroup, BFormCheckbox, BFormCheckboxGroup } from 'bootstrap-vue';
import { ref, watch, computed, onUnmounted, onMounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import flatPickr from 'vue-flatpickr-component';
import Cleave from 'vue-cleave-component';
import { validatorEmail } from '@core/utils/validations/validators';
import useAccountsList from '@/views/apps/accounting/receivable/account/list/useAccountsList';
import moment from 'moment';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import ReconcilePaymentList from '@/views/apps/accounting/receivable/reconcile/ReconcilePaymentList.vue';
import ReconcilePaymentSummary from '@/views/apps/accounting/receivable/reconcile/ReconcilePaymentSummary.vue';

export default {
  components: {
    ReconcilePaymentList,
    ReconcilePaymentSummary,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    flatPickr,
    Cleave,
  },
  setup() {
    const studentData = ref(null);

    const querySsn = ref('');
    const queryEmail = ref('');
    const queryDates = ref('');
    //         query.paymentDateFilter = paymentDateFilter.value.map((z) => moment(z).format('YYYY-MM-DD')).join(',');

    const payments = ref([]);

    const isSsnShort = computed(() => {
      return querySsn.value && querySsn.value.length <= 4;
    });

    const isSsnComplete = computed(() => {
      return querySsn.value && ((isSsnShort && querySsn.value.length === 4) || querySsn.value.length === 11);
    });

    const isValidEmail = computed(() => {
      return queryEmail.value && validatorEmail(queryEmail.value);
    });

    const inputSsnOptions = computed(() => {
      return isSsnShort.value ? { blocks: [5], numericOnly: true } : { blocks: [3, 2, 4], numericOnly: true, delimiter: '-' };
    });

    const clearQueries = () => {
      console.log(`cleared queries`);
      querySsn.value = '';
      queryEmail.value = '';
    };

    const isResetOnHotkey = ref(true);

    const onUpdate = function (_) {
      console.log(`on update!`, isResetOnHotkey.value);
      if (isResetOnHotkey.value) {
        clearQueries();
      }

      console.log(
        `onUpdate`,
        markedAsPaid.value.filter((account) => account.payment._id === _.payment._id),
      );

      if (markedAsPaid.value.filter((account) => account.payment._id === _.payment._id).length === 0) {
        _.payment.status = 'paid';
        markedAsPaid.value.push(_);
        saveSession();
      }
    };

    const onUnpaid = function (_) {
      markedAsPaid.value = markedAsPaid.value.filter((account) => account.payment._id !== _.payment._id);
      saveSession();
    };

    const keyup = (event) => {
      if (event.key === 'Escape') {
        clearQueries();
      }
    };

    const sessionId = ref(router.currentRoute.query.session);
    const session = ref({});
    const markedAsPaid = ref([]);

    onMounted(() => {
      if (!sessionId.value) {
        const id = Date.now();
        sessionId.value = id;
        router.push({ query: { session: id } });
      }

      // garbage collection
      for (let key of Object.keys(localStorage)) {
        if (key.startsWith('ar_rw_s')) {
          const [, timestamp] = key.split('ar_rw_s_');
          const summary = JSON.parse(localStorage.getItem(key));

          if (summary && summary.date && Date.now() - summary.date > 86400 * 7 && sessionId.value !== timestamp) {
            localStorage.removeItem;
          }
        }
      }

      loadSession(true);
    });

    const lsKey = computed(() => {
      return `ar_rw_s_${sessionId.value}`;
    });

    const loadSession = (saveDefault = false) => {
      const lsObj = { date: Date.now(), payments: [] };

      const _ = localStorage.getItem(lsKey.value);

      let existing = _ ? JSON.parse(_) : { ...lsObj };
      markedAsPaid.value = existing.payments;

      session.value = existing;

      if (saveDefault) saveSession();
      return existing;
    };

    const saveSession = () => {
      localStorage.setItem(
        lsKey.value,
        JSON.stringify({
          date: session.value.date,
          payments: markedAsPaid.value,
        }),
      );
    };

    watch(markedAsPaid.value, () => {});

    return {
      querySsn,
      queryEmail,
      queryDates,

      isSsnShort,
      inputSsnOptions,
      isResetOnHotkey,
      clearQueries,
      keyup,
      onUpdate,
      onUnpaid,

      markedAsPaid,
    };
  },
};
</script>

<style></style>
